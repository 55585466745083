import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

interface NavLinkButtonProps {
  to: string;
  color:
    | 'blue'
    | 'red'
    | 'gradient-purple-blue'
    | 'gradient-purple-red'
    | 'gradient-red-purple-blue';
  text: string;
  showIconMobile?: boolean;
  icon?: IconProp;
  className?: string;
}

function NavLinkButton({ to, color, text, showIconMobile, icon, className }: NavLinkButtonProps) {
  let classes = `${className} py-2 px-6 focus:outline-none focus:shadow-outline-red text-white rounded-md transition duration-300 cursor-pointer`;
  if (color === 'red') {
    classes += ' bg-red-500 hover:bg-red-600';
  } else if (color === 'gradient-purple-blue') {
    classes += ' bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl';
  } else if (color === 'gradient-purple-red') {
    classes += ' bg-gradient-to-br from-purple-500 to-red-500 hover:bg-gradient-to-bl';
  } else if (color === 'gradient-red-purple-blue') {
    classes += ' bg-gradient-to-br from-blue-500 via-purple-500 to-red-500 hover:bg-gradient-to-bl';
  } else {
    classes += ' bg-blue-600 hover:bg-blue-700';
  }

  return (
    <NavLink to={to} className={classes}>
      {icon && (
        <FontAwesomeIcon icon={icon} className={`${showIconMobile ? 'mr-2' : 'hidden md:block'}`} />
      )}
      {text}
    </NavLink>
  );
}

export default NavLinkButton;
