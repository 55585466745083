import { Suspense, lazy } from 'react';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import './App.css';
import Loader from './components/ui/Loader/Loader';
import NavigationBar from './components/ui/NavigationBar/NavigationBar';
import NavigationBarNotLogged from './components/ui/NavigationBarNotLogged/NavigationBarNotLogged';
import { ScrollToTop } from './components/ui/ScrollToTop/ScrollToTop';
import { ConfigContext } from './contexts/ConfigContext';
import { DomainContext } from './contexts/DomainContext';
import { UserContext } from './contexts/UserContext';
import AdminGuard from './guards/AdminGuard';
import UserGuard from './guards/UserGuard';
import useConfig from './hooks/useConfig';
import useUser from './hooks/useUser';

const AgesPageWithData = lazy(() => import('./pages/AgesPageWithData'));
const CoinFormPageWithData = lazy(() => import('./pages/CoinFormPageWithData'));
const EditAgePageWithData = lazy(() => import('./pages/EditAgePageWithData'));
const EditPlanPageWithData = lazy(() => import('./pages/EditPlanPageWithData'));
const EditRulerPageWithData = lazy(() => import('./pages/EditRulerPageWithData'));
const IndexPageWithData = lazy(() => import('./pages/IndexPageWithData'));
const ListPageWithData = lazy(() => import('./pages/ListPageWithData'));
const LoginPageWithData = lazy(() => import('./pages/LoginPageWithData'));
const ManageAccountPageWithData = lazy(() => import('./pages/ManageAccountPageWithData'));
const ManageAgesPageWithData = lazy(() => import('./pages/ManageAgesPageWithData'));
const ManageImagesPageWithData = lazy(() => import('./pages/ManageImagesPageWithData'));
const ManagePlansPageWithData = lazy(() => import('./pages/ManagePlansPageWithData'));
const ManageUsersPageWithData = lazy(() => import('./pages/ManageUsersPageWithData'));
const ManageSubscriptionsPageWithData = lazy(
  () => import('./pages/ManageSubscriptionsPageWithData'),
);
const NotFoundPage = lazy(() => import('./components/pages/NotFoundPage'));
const OverviewPageWithData = lazy(() => import('./pages/OverviewPageWithData'));
const RegistrationPageWithData = lazy(() => import('./pages/RegistrationPageWithData'));
const SearchPageWithData = lazy(() => import('./pages/SearchPageWithData'));
const SubscriptionPageWithData = lazy(() => import('./pages/SubscriptionPageWithData'));

function App() {
  const domainElement: HTMLInputElement | null = document.getElementById(
    'domain',
  ) as HTMLInputElement;
  let domain = '';
  domain = domainElement.value;

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { data: userData, isLoading: isUserLoading } = useUser(domain);
  const user = userData?.data ?? null;

  const { data: configData, isLoading: isConfigLoading } = useConfig(domain);
  const config = configData?.data ?? null;

  if (!domainElement) {
    return <div>domain is not set</div>;
  }

  if (isUserLoading || isConfigLoading) {
    return <Loader />;
  }

  const showBackground = location.pathname === '/' && !searchParams.get('q') && !user;

  const getIndexPage = () => {
    if (!user) {
      return <IndexPageWithData />;
    }

    return <SearchPageWithData />;
  };

  return (
    <DomainContext.Provider value={domain}>
      <UserContext.Provider value={user}>
        <ConfigContext.Provider value={config}>
          <div className={`${showBackground ? 'background' : ''} mx-auto w-full max-w-screen-3xl`}>
            <div className="content">
              <div className="mx-auto w-[90%] max-w-8xl py-2 sm:w-full sm:px-6 lg:px-8">
                {user ? (
                  <NavigationBar user={user} />
                ) : (
                  <NavigationBarNotLogged
                    isRegistrationActive={config?.ENABLE_REGISTRATION || false}
                    isBackgroundActive={showBackground}
                  />
                )}
                <ScrollToTop />
                <Suspense fallback={<Loader />}>
                  <Routes>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route path="/" element={getIndexPage()} />
                    <Route path="/login" element={<LoginPageWithData user={user} />} />
                    <Route path="/overview" element={<OverviewPageWithData />} />
                    <Route path="/manage-account" element={<ManageAccountPageWithData />} />
                    {config?.ENABLE_REGISTRATION && (
                      <Route path="/register" element={<RegistrationPageWithData user={user} />} />
                    )}

                    <Route element={<UserGuard />}>
                      <Route path="/age/:ageId" element={<AgesPageWithData />} />
                      <Route
                        path="/ruler/:rulerId"
                        element={<ListPageWithData order="year:asc,name:asc,sold_at:desc" />}
                      />
                      <Route path="/ages" element={<AgesPageWithData />} />
                      <Route path="/subscription" element={<SubscriptionPageWithData />} />
                    </Route>

                    <Route element={<AdminGuard />}>
                      <Route path="/age/add" element={<EditAgePageWithData />} />
                      <Route path="/age/add/:parentId" element={<EditAgePageWithData />} />
                      <Route path="/age/edit/:ageId" element={<EditAgePageWithData />} />
                      <Route path="/coin/add" element={<CoinFormPageWithData />} />
                      <Route path="/coin/edit/:coinId" element={<CoinFormPageWithData />} />
                      <Route path="/coin/image/:coinId" element={<ManageImagesPageWithData />} />
                      <Route
                        path="/coin/last"
                        element={<ListPageWithData order="created_at:desc" />}
                      />
                      <Route path="/manage-age" element={<ManageAgesPageWithData />} />
                      <Route path="/manage-age/:ageId" element={<ManageAgesPageWithData />} />
                      <Route path="/manage-plans" element={<ManagePlansPageWithData />} />
                      <Route path="/manage-users" element={<ManageUsersPageWithData />} />
                      <Route
                        path="/manage-subscriptions/:userId"
                        element={<ManageSubscriptionsPageWithData />}
                      />
                      <Route path="/plan/add" element={<EditPlanPageWithData />} />
                      <Route path="/plan/edit/:planId" element={<EditPlanPageWithData />} />
                      <Route path="/ruler/add/:ageId" element={<EditRulerPageWithData />} />
                      <Route path="/ruler/edit/:rulerId" element={<EditRulerPageWithData />} />
                    </Route>
                  </Routes>
                </Suspense>
              </div>
            </div>
          </div>
        </ConfigContext.Provider>
      </UserContext.Provider>
    </DomainContext.Provider>
  );
}

export default App;
