import { FC } from 'react';
import {
  faUserCircle,
  faCoins,
  faCrown,
  faPlusCircle,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { User } from '../../../types/User';
import { NavigationLink } from './NavigationLink';
import { NavigationMenu } from './NavigationMenu';

interface NavigationProps {
  user: User;
}

const NavigationBar: FC<NavigationProps> = ({ user }) => (
  <nav className="flex w-full items-center py-2">
    <NavLink to="/" title="Groše - hlavní strana">
      <img className="mb-2 w-16 sm:w-20" src="https://www.grose.cz/grose.png" alt="Groše" />
    </NavLink>
    <div className="hidden lg:inline-block">
      <NavigationLink to="/" title="Vyhledávání" icon={faSearch} />
      <NavigationLink to="/ages" title="Katalog období" icon={faCrown} />
      {user && user.role === 'admin' && (
        <>
          <NavigationLink to="/coin/last" title="Poslední přidané" icon={faCoins} />
          <NavigationLink to="/coin/add" title="Přidat prodej mince" icon={faPlusCircle} />
        </>
      )}
    </div>
    <div className="ml-auto">
      <NavigationLink to="/overview" title="Přehled" icon={faUserCircle} />
      <NavigationMenu user={user} />
    </div>
  </nav>
);

export default NavigationBar;
