import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ButtonProps {
  color:
    | 'blue'
    | 'red'
    | 'white'
    | 'gradient-purple-blue'
    | 'gradient-purple-red'
    | 'gradient-red-purple-blue';
  text: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  icon?: IconProp;
  showIconMobile?: boolean;
  className?: string;
}

function Button({
  color,
  text,
  onClick,
  loading,
  disabled,
  icon,
  showIconMobile,
  className,
}: ButtonProps) {
  let classes = `${className || ''} h-10 w-full flex items-center justify-center md:w-auto py-2 px-8 focus:outline-none focus:shadow-outline-blue rounded-md transition duration-300 cursor-pointer`;
  if (color === 'red') {
    classes +=
      ' text-white bg-red-500 hover:bg-red-600 disabled:bg-gray-300 disabled:cursor-not-allowed';
  } else if (color === 'gradient-purple-blue') {
    classes +=
      ' text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl disabled:from-gray-300 disabled:to-gray-300 disabled:cursor-not-allowed';
  } else if (color === 'gradient-purple-red') {
    classes +=
      ' text-white bg-gradient-to-br from-purple-500 to-red-500 hover:bg-gradient-to-bl disabled:from-gray-300 disabled:to-gray-300 disabled:cursor-not-allowed';
  } else if (color === 'gradient-red-purple-blue') {
    classes +=
      ' text-white bg-gradient-to-br from-red-500 via-purple-500 to-blue-500 hover:bg-gradient-to-bl disabled:from-gray-300 disabled:to-gray-300 disabled:cursor-not-allowed';
  } else if (color === 'blue') {
    classes +=
      ' text-white bg-blue-600 hover:bg-blue-700 disabled:bg-gray-300 disabled:cursor-not-allowed';
  } else {
    classes +=
      ' border text-gray-700 hover:bg-gray-50 disabled:bg-gray-300 disabled:cursor-not-allowed';
  }

  return (
    <button
      type="submit"
      className={classes}
      onClick={() => (onClick ? onClick() : {})}
      disabled={disabled}
    >
      {!loading && icon && (
        <FontAwesomeIcon
          icon={icon}
          className={`${showIconMobile ? 'mr-2' : 'hidden md:mr-2 md:block'}`}
        />
      )}
      {!loading && text}
      {loading && <FontAwesomeIcon icon={faSpinner} className="animate-spin" />}
    </button>
  );
}

export default Button;
