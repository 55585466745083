import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

function AdminGuard() {
  const user = useContext(UserContext);

  if (user?.role !== 'admin') {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}

export default AdminGuard;
