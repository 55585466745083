import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import BlockedAccountPage from '../components/pages/BlockedAccountPage';
import { UserContext } from '../contexts/UserContext';

function UserGuard() {
  const user = useContext(UserContext);

  if (!user?.email_verified_at) {
    return <BlockedAccountPage />;
  }

  return <Outlet />;
}

export default UserGuard;
