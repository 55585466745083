function BlockedAccountPage() {
  return (
    <div className="flex h-96 flex-col justify-center">
      <h3 className="text-center text-2xl font-bold sm:text-3xl">
        Vaše e-mailová adresa není ověřena
      </h3>
    </div>
  );
}

export default BlockedAccountPage;
