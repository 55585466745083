import { NavLink } from 'react-router-dom';
import NavLinkButton from '../../elements/NavLinkButton/NavLinkButton';

interface NavigationBarNotLoggedProps {
  isRegistrationActive: boolean;
  isBackgroundActive: boolean;
}

const NavigationBarNotLogged = ({
  isRegistrationActive,
  isBackgroundActive,
}: NavigationBarNotLoggedProps) => (
  <nav className="flex w-full items-center py-2">
    <NavLink to="/" title="Groše - hlavní strana">
      <img
        className="mb-2 w-16 sm:w-20"
        src={`${isBackgroundActive ? 'https://www.grose.cz/grose-white.png' : 'https://www.grose.cz/grose-black.png'}`}
        alt="Groše"
      />
    </NavLink>
    <div className="ml-auto flex items-center gap-4">
      <NavLink
        to="/login"
        className={({ isActive }) => {
          if (isBackgroundActive) {
            return 'text-white';
          }
          if (isActive) {
            return 'text-grose-blue';
          }
          return 'text-gray-700 hover:text-grose-blue-hover';
        }}
      >
        Přihlásit se
      </NavLink>
      {isRegistrationActive && <NavLinkButton to="/register" color="blue" text="Registrace" />}
    </div>
  </nav>
);

export default NavigationBarNotLogged;
